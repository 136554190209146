<template>
  <div class="invoice--wrapper p-4" v-if="document">
    <div class="invoice--watermark" v-if="document.paid_at">
      <div class="inner--watermark">
        <img
          src="https://rentfixs3-sg.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/icon-transparent.png"
        />
        <div>{{ $t('invoice.paid') }}</div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-auto">
        <img :src="logoUrl" alt="Logo Propfix" height="50" />
      </div>
      <div class="col text-center">
        <div class="document--title" v-if="document.listing_type === 'R'">
          {{ $t('invoice.rentInvoice') }}
        </div>
        <div class="document--title" v-else-if="document.listing_type === 'S'">
          {{ $t('invoice.buyInvoice') }}
        </div>
      </div>
    </div>
    <div class="top--section my-4">
      <div class="row">
        <div class="col-4 px-5">
          <div class="d-flex justify-content-between mb-1">
            <div class="bold--text">{{ $t('invoice.date') }}:</div>
            <div>{{ $date.format(document.created_at, 'dd MMMM yyyy') }}</div>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-6 px-5">
          <div class="d-flex justify-content-between mb-1">
            <div class="bold--text">{{ $t('invoice.invoiceNumber') }}:</div>
            <div>{{ document.invoice_number }}</div>
          </div>
          <div class="d-flex justify-content-between mb-1">
            <div class="bold--text">{{ $t('invoice.ownerName') }}:</div>
            <div>{{ document.owner_name }}</div>
          </div>
          <div class="d-flex justify-content-between mb-1">
            <div class="bold--text">{{ $t('invoice.applicantName') }}:</div>
            <div>{{ document.applicant_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle--section mb-4">
      <div class="bg--grey px-3 py-2">
        <div class="document--title" v-if="document.listing_type === 'R'">
          {{ $t('invoice.rentSummary') }}
        </div>
        <div class="document--title" v-else-if="document.listing_type === 'S'">
          {{ $t('invoice.buySummary') }}
        </div>
      </div>
      <div class="px-3 pb-2">
        <div class="p-4" v-if="document.listing_type === 'R'">
          <div class="mb-3">{{ $t('general.propertyType') }}: {{ document.property_type }}</div>
          <div class="mb-3">{{ $t('general.address2') }}: {{ document.address }}</div>
          <div class="mb-3">{{ $t('general.rentPeriod') }}: {{ document.period }}</div>
          <div>{{ $t('general.rentDuration') }}: {{ document.duration }}</div>
        </div>
        <div class="p-4" v-else-if="document.listing_type === 'S'">
          <div class="mb-3">{{ $t('general.propertyType') }}: {{ document.property_type }}</div>
          <div>{{ $t('general.address2') }}: {{ document.address }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="bottom--section mb-1">
          <div class="bg--grey px-3 py-2">
            <div class="document--title" v-if="document.listing_type === 'R'">
              {{ $t('invoice.transactionSummary') }}
            </div>
            <div class="document--title" v-else-if="document.listing_type === 'S'">
              {{ $t('invoice.reservationSummary') }}
            </div>
          </div>
          <div class="p-3">
            <div class="row mb-3 p-0">
              <div class="col-6">
                <span v-if="document.listing_type === 'R'">{{ $t('addListing.rentPrice') }}</span>
                <span v-else-if="document.listing_type === 'S'">{{
                  $t('addListing.sellPrice')
                }}</span>
              </div>
              <div class="col-6">
                <span v-if="document.listing_type === 'R'">{{
                  $n(document.rent_price, 'currency', 'en-SG')
                }}</span>
                <span v-else-if="document.listing_type === 'S'">{{
                  $n(document.sell_price, 'currency', 'en-SG')
                }}</span>
              </div>
            </div>
            <div class="row mb-3 p-0" v-if="document.promo_discount">
              <div class="col-6">
                {{ $t('invoice.promoDiscount') }}
              </div>
              <div class="col-6 document--promo">
                <div>- {{ $n(document.promo_discount, 'currency', 'en-SG') }}</div>
              </div>
            </div>
            <div class="row" v-if="document.listing_type === 'R' || document.booking_fee">
              <div class="col-6">
                <span class="bold--text" v-if="document.listing_type === 'R'">{{
                  $t('general.bookingFee')
                }}</span>
                <span class="bold--text" v-else-if="document.listing_type === 'S'">{{
                  $t('invoice.reservationPrice')
                }}</span>
              </div>
              <div class="col-6">
                <span class="bold--text" v-if="document.listing_type === 'R'">{{
                  $n(document.pay_amount, 'currency', 'en-SG')
                }}</span>
                <span class="bold--text" v-else-if="document.listing_type === 'S'">{{
                  $n(document.booking_fee, 'currency', 'en-SG')
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="total--section py-2 px-3 bg--grey">
          <div class="row p-0 align-items-center">
            <div class="col-6 document--subtitle">
              <div>{{ $t('invoice.paidTotal') }}</div>
            </div>
            <div class="col-6 bold--text">
              {{ $n(document.pay_amount, 'currency', 'en-SG') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="paid--section row mt-4">
      <div class="col-6"></div>
      <div class="col-6 px-4" v-if="document.paid_at">
        {{ $t('invoice.paidAt') }}: {{ $date.format(document.paid_at, 'dd MMMM yyyy HH:mm') }}
      </div>
    </div>
    <div class="footer--section">Invoice: {{ document.invoice_number }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      logoUrl: state => state.global.logoUrl,
      isLoggedIn: state => state.global.loggedIn,
      token: state => state.global.token,
      document: state => state.v2.booking.detail.transactionDocument.document,
    }),
  },
  mounted() {
    this.$store.dispatch('v2/booking/detail/transactionDocument/getInvoice', {
      orderNumber: this.$route.query.order_number,
      token: this.isLoggedIn ? this.token : this.$route.query.token,
      feKey: this.$route.query.fe_key,
    });
  },
};
</script>
